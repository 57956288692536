import { Trans } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { Text } from 'Text';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';

export const SomethingWentWrong: ErrorHandlingComponent = ({ resetErrorBoundary }) => {
    const location = window.location.pathname;
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        // If you get to this fallback/default error handling component
        // at least attempt to reset the error on navigation
        resetErrorBoundary();
    }, [location, resetErrorBoundary]);

    return (
        <div className="flex h-2/6 w-full items-center justify-center @container">
            <Text
                as="h1"
                className="m-auto w-full text-center text-xs font-normal @xs:text-sm @sm:text-base @sm:font-semibold @md:text-lg @lg:text-xl"
            >
                <Trans i18nKey="front_royal_api_error_handler.api_error_handler.something_went_wrong_check_back_later" />
            </Text>
        </div>
    );
};
