import ServerTime, {
    getServerClientTimeOffset,
    ensureServerClientTimeOffsetOnWindow,
    setServerClientTimeOffetOnWindow,
} from 'ServerTime';
import { DisconnectedError } from 'DisconnectedError';
import angularModule from './front_royal_config_module';

// a singleton that caches the result of a /api/config.json to back_royal
// that returns env-specific config
/*
    This factory is also responsible for determining the approximate server time,
    which is used in event logging for estimating that time at which events really
    happened.

    Server time is sent from the server along with the config, either baked
    into the html or in the meta of the api request.

    When we receive the server
    time in the client, we make the assumption that the request took exactly as
    long to reach the server as the response took to get back to the client.  So,
    if we make a request to the server, and it takes 10 seconds for us to get the response,
    we assume that 5 seconds have passed since the returned server time was generated,
    and we set our local server time to now() - 5.seconds.

    If the request for the time takes longer than the `allowableError` to come back, then
    we request it again.  So if the allowableError is 60 seconds, then out server time
    might be off by as much as 60 seconds.  There is a tradeoff here.  The larger error we
    allow, the less likely it is that we will have to repeat a request for the server time.
*/

angularModule.factory('ConfigFactory', [
    '$injector',
    $injector => {
        const $q = $injector.get('$q');
        const $rootScope = $injector.get('$rootScope');
        const Config = $injector.get('Config');
        const injector = $injector.get('injector');
        const frontRoyalStore = injector.get('frontRoyalStore', { optional: true });
        const offlineModeManager = injector.get('offlineModeManager', { optional: true });
        let ConfigFactory;

        // See comments above
        const allowableError = 60 * 1000;

        // Whenever we have a config object, we want to save
        // the object to the store.
        //
        // NOTE: We never change anything inside of _config once it is set.  When we push down
        // config changes, we replace the config entirely. See also: pushUpdatedPropertiesOntoConfig
        $rootScope.$watch(
            () => ConfigFactory._config,
            () => {
                ConfigFactory.saveConfigToFrontRoyalStore();
            },
        );

        ConfigFactory = {
            _getConfigFromApiOrStore() {
                // We always want to try to load the config from the server, even if
                // we have it in the store, just in case something has changed.
                // Only if the api call fails with a disconnected error and we
                // switch to offline mode do we load the config from the store.
                return offlineModeManager
                    .rejectInOfflineMode(() => this._loadFromApi())
                    .catch(err => {
                        if (err.constructor !== DisconnectedError) {
                            throw err;
                        }

                        return this._loadFromStore();
                    })
                    .then(config => {
                        /*
                            We never expect to get here without a config object.
                            Either

                            1. _loadFromApi succeeded as normal, in which
                                case we would have a config object
                            2. _loadFromApi failed with some fatal error, in
                                which case we never get here
                            3. the api call failed with a disconnected error and
                                we could not switch to offline mode, in which case
                                _loadFromApi will just hang, the user will
                                see the network disconnected UI, and we don't get
                                here unless the call can be successfully retried
                            4. the api call failed with a disconnected error and
                                we did switch into offline mode, which can only
                                happen if there is a config object in the store (see
                                OfflineModeManager#isOfflineModeSupported()), in which
                                case we would have that config object that was pulled
                                from the store
                        */
                        if (!config) {
                            const DialogModal = $injector.get('DialogModal');
                            DialogModal.showFatalError();
                            throw new Error('Could not load config from either api or store');
                        }

                        return config;
                    });
            },

            _loadFromStore() {
                return frontRoyalStore.getConfig().then(record => {
                    if (record) {
                        this._config = Config.new(record);
                        this.serverTime = new ServerTime(this._config.serverClientTimeOffset);
                    }

                    return $q.when(this._config);
                });
            },

            _loadFromApi() {
                const self = this;
                const sentAt = this._now();
                return Config.index().then(response => {
                    // If it took longer than allowableError to make this request,
                    // then we can't determine the server time.  Try again.
                    if (!self._setServerTime(1000 * response.meta.server_timestamp, this._now() - sentAt)) {
                        return self._loadFromApi();
                    }

                    self._config = response.result[0];

                    return self._config;
                });
            },

            get serverTime() {
                return this._serverTime;
            },

            set serverTime(val) {
                this._serverTime = val;

                // Once we have a ConfigFactory, and it has a serverClientTimeOffset, set that
                // on the window so that any code using ensureServerClientTimeOffsetOnWindow will get the
                // same value
                setServerClientTimeOffetOnWindow(val.serverClientTimeOffset);
            },

            isInitialized() {
                return !!this._config;
            },

            saveConfigToFrontRoyalStore() {
                if (this._config) {
                    frontRoyalStore.retryOnHandledError(db => {
                        db.configRecords.put({
                            // We always use the same id so that this
                            // always replaces the single config object
                            id: 'frontRoyalConfig',
                            serverClientTimeOffset: ConfigFactory.serverTime.serverClientTimeOffset,
                            ...this._config.asJson(),
                        });
                    });
                }
            },

            getConfig() {
                if (this.promise) {
                    return this.promise;
                }
                this.promise = offlineModeManager ? this._getConfigFromApiOrStore() : this._loadFromApi();
                return this.promise;
            },

            reloadFromApi() {
                // There is a watcher above that will ensure that the new config gets saved to the store
                return this._loadFromApi();
            },

            getSync(withoutThrow) {
                if (this.isInitialized()) {
                    return this._config;
                }
                if (!withoutThrow) {
                    throw new Error('Config is not yet initialized');
                }
                return null;
            },

            // NOTE: This method is dangerous. It should only ever
            // be used by `ConfigInterceptor`, when intercepting new
            // config values sent down from the server.
            pushUpdatedPropertiesOntoConfig(props = {}) {
                // It is important that we create a new object here. See comment near $rootScope.$watch above
                // and in watchConfig
                this._config = Config.new({
                    ...this._config,
                    ...props,
                });
                this.promise = $q.when(this._config);
            },

            // public method that returns the current server time as a timestamp
            // if a value for 'now' is passed in, use that instead of the current Date.now() value
            getServerTimestamp(now) {
                if (!this.serverTime && window.RUNNING_IN_TEST_MODE) {
                    return Date.now();
                }

                if (!this.serverTime) {
                    throw new Error('Cannot determine server time.');
                }

                return this.serverTime.getServerTimestamp(now || this._now());
            },

            watchConfig(callback) {
                // NOTE: We never change anything inside of _config once it is set.  When we push down
                // config changes, we replace the config entirely. See also: pushUpdatedPropertiesOntoConfig
                return $rootScope.$watch(
                    () => this._config,
                    () => {
                        if (this._config) {
                            callback(this._config);
                        }
                    },
                );
            },

            _setServerTime(serverGeneratedTimestamp, timeSinceRequest) {
                if (timeSinceRequest < allowableError) {
                    const offset = getServerClientTimeOffset(serverGeneratedTimestamp, timeSinceRequest, this._now());
                    this.serverTime = new ServerTime(offset);
                    return true;
                }

                return false;
            },

            // mockable in specs
            _now() {
                return Date.now();
            },
        };

        // On the web, we bake the config into the html in order
        // to save an api call.  In cordova we probably want to
        // stick with the api call.
        //
        // If window.performance.now() is more than the allowableError,
        // that means that it was a very long time between when the initial
        // request was made to load the page and when we go there.  In that
        // case, the serverClientTimeOffset can be way off, so we make an api
        // request in order to regenerate it.
        if (window.preloadedConfig && window.performance.now() < allowableError) {
            ConfigFactory.serverTime = new ServerTime(ensureServerClientTimeOffsetOnWindow());
            const config = Config.new(window.preloadedConfig);
            ConfigFactory._config = config;
            ConfigFactory.promise = $q.when(config);
        }

        return ConfigFactory;
    },
]);
