/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { careerProfilesApi } from 'CareerProfiles';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type navigationHelper } from 'NavigationHelpers';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';
import { buttonClasses } from './classes';

const { useGetRecommendedClassmatesQuery } = careerProfilesApi;

type MeetYourClassProps = {
    loadRoute: ReturnType<typeof navigationHelper>['loadRoute'];
};

// eslint-disable-next-line max-lines-per-function
export const MeetYourClass: FC<MeetYourClassProps> = ({ loadRoute }) => {
    const { data, isLoading } = useGetRecommendedClassmatesQuery();
    const { t } = useTranslation('back_royal');

    return (
        <div>
            <h2 className="mb-3.5 ml-2.5 mr-0 mt-0 text-lg font-semibold uppercase text-beige-beyond-dark">
                {t('featured_students.featured_students.meet_your_class')}
            </h2>
            <div className="relative flex h-[425px] flex-col justify-between rounded bg-white px-3 pt-2.5">
                {isLoading ? (
                    <FrontRoyalSpinner className="no-delay mx-auto my-[180px]" />
                ) : (
                    <>
                        <div>
                            {data?.map(({ jobTitle, name, userId, avatarUrl, company }) => (
                                <div
                                    className="mb-1.5 flex cursor-pointer gap-3 p-1 hover:rounded hover:bg-black/[.03]"
                                    key={userId}
                                    onClick={() => {
                                        loadRoute(`/student-network?ids=${userId}`);
                                    }}
                                >
                                    <img
                                        src={avatarUrl || defaultAvatarSrc}
                                        alt={name || 'user avatar'}
                                        className="h-[43px] w-[43px] rounded object-cover"
                                    />
                                    <div className="flex flex-initial basis-[222px] flex-col lg:basis-[270px]">
                                        <p className="mb-0.5 pt-0.5 text-sm font-semibold capitalize leading-4.5 text-beige-for-text">
                                            {name}
                                        </p>
                                        {jobTitle ? (
                                            <p className="m-0 text-xxs leading-3 text-beige-beyond-dark">
                                                {company ? `${company}, ${jobTitle}` : jobTitle}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="relative bottom-0">
                            <Tooltip
                                title={t('featured_students.featured_students.tooltip_message')}
                                arrow
                                placement="top"
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            font: 'normal 12px ProximaNovaSoft',
                                        },
                                    },
                                }}
                            >
                                <i className="fa-light fa-circle-info absolute left-3.5 top-[7px] flex text-beige-middark" />
                            </Tooltip>
                            <button
                                type="button"
                                className={clsx(buttonClasses, 'mt-0')}
                                onClick={() => {
                                    loadRoute('/student-network?myClass');
                                }}
                            >
                                {t('featured_students.featured_students.view_classmates')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
