import { useLocation, type createBrowserRouter, type createHashRouter, useNavigation } from 'react-router-dom';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { angularInjectorProvider } from 'Injector';
import { REACT_ROUTER_DIRECTIVE_NAME, type FrontRoyalRouteService } from 'NavigationHelpers';
import { cleanUpRouteResolution } from './cleanUpRouteResolution';
import { useRootScope } from './useRootScope';

function handleReactRouting(
    router: ReturnType<typeof createBrowserRouter> | ReturnType<typeof createHashRouter>,
    $route: FrontRoyalRouteService,
) {
    let currentPathname = window.location.pathname;
    const searchParams = window.location.search;
    const $$route = $route.current?.$$route;
    const directiveName: string | null = $$route?.directive || null;
    const routeHandledBy = directiveName === REACT_ROUTER_DIRECTIVE_NAME ? 'react' : 'angular';

    // When the route is handled by both angular and react, we let angular
    // determine what routeHandledBy should be set to. See routes.js for an example.
    if ($$route && !$$route.handledByBothAngularAndReact) {
        $$route.routeHandledBy = routeHandledBy;
    }

    if (window.CORDOVA) {
        const hash = window.location.hash;
        currentPathname = hash && hash.match('#') ? hash.split('#')[1] : '/';
    }
    const target = `${currentPathname}${searchParams}`;
    router.navigate(target, { replace: true });
}

// Observe route changes kicked off by angular and update the react router accordingly
export function useRespondToAngularRouteChanges(
    router: ReturnType<typeof createBrowserRouter> | ReturnType<typeof createHashRouter>,
) {
    const rootScope = useRootScope();
    const $route = angularInjectorProvider.requireInjector().get<FrontRoyalRouteService>('$route');

    useEffectOnce(() => {
        handleReactRouting(router, $route);
    });

    useEffect(() => {
        const off = rootScope.$on('$routeChangeSuccess', () => {
            handleReactRouting(router, $route);
        });
        return off;
    }, [router, rootScope, $route]);
}

// Let angular know whenever the react route changes (for event logging)
export function useEmitRouteChangeSuccessOnReactRouteChange() {
    const { pathname } = useLocation();
    const navigation = useNavigation();
    const $rootScope = useRootScope();
    const $injector = angularInjectorProvider.requireInjector();
    const $route = $injector.get<FrontRoyalRouteService>('$route');
    const routeHandledBy = $route.current?.$$route?.routeHandledBy;

    useEffect(() => {
        if (routeHandledBy !== 'react') return;

        if (navigation.state === 'loading') {
            $route.frontRoyalIsResolvingRoute = true;
        } else if (navigation.state === 'idle') {
            cleanUpRouteResolution($injector);
        }
    }, [navigation.state, routeHandledBy, $route, $injector]);

    useEffect(() => {
        $rootScope.$emit('reactRouteChangeSuccess');
    }, [$rootScope, pathname]);
}
