import parse, { domToReact, Element, type HTMLReactParserOptions } from 'html-react-parser';
import { Icon, type GlobalIconName } from 'FrontRoyalIcon';
import { InlineLink } from 'InlineLink';
import { type LinkTarget } from 'NavigationHelpers';

export const parseHtml = (html: string) => {
    const options: HTMLReactParserOptions = {
        // eslint-disable-next-line consistent-return
        replace: domNode => {
            if (domNode instanceof Element) {
                const { type, name, attribs = {}, children } = domNode;
                if (type === 'tag' && name === 'icon') {
                    const { iconname, ...rest } = attribs;
                    return <Icon {...rest} iconName={iconname as GlobalIconName} />;
                }

                if (type === 'tag' && name === 'a' && children) {
                    const { href = '#', class: className, target } = attribs;

                    return (
                        <InlineLink target={target as LinkTarget} href={href} className={className}>
                            {domToReact(children, options)}
                        </InlineLink>
                    );
                }
            }
        },
    };
    return parse(html, options);
};
