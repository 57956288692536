import { useEffect, useState } from 'react';
import delay from 'delay';
import { convertCurrentUserFromIguana, type CurrentUser, type CurrentUserIguanaObject } from 'Users';
import { useRootScope } from './useRootScope';

export function useCurrentUserIguanaObject(): CurrentUserIguanaObject | null {
    const $rootScope = useRootScope();
    const [_, setForceUpdate] = useState(0);

    useEffect(() => {
        const removeWatcher = $rootScope.$watchCollection('currentUser', () => {
            setForceUpdate(c => c + 1);
        });

        return removeWatcher;
    }, [$rootScope]);

    return $rootScope.currentUser ?? null;
}

export const useCurrentUser = () => {
    const $rootScope = useRootScope();
    const [currentUser, setCurrentUser] = useState(() => convertCurrentUserFromIguana($rootScope.currentUser ?? null));

    useEffect(() => {
        const removeWatcher = $rootScope.$watchCollection('currentUser', (user: CurrentUserIguanaObject) => {
            setCurrentUser(convertCurrentUserFromIguana(user));
        });

        return removeWatcher;
    }, [$rootScope]);

    return currentUser;
};

export const useSuspenseCurrentUser = (): CurrentUser | null => {
    const $rootScope = useRootScope();
    const currentUser = useCurrentUser();

    // Suspend until the auth call is complete.
    if (!$rootScope.authCallComplete) {
        throw delay(100);
    }

    // The auth call is complete, so we can return the user. Keep in mind that just
    // because the auth call is complete, it doesn't mean there is a current user.
    return currentUser;
};

// We want to use this version of the hook when we really should be able to guarantee a user exists, i.e in an angular context
// and where we have already guaranteed the user exists in a parent component
export const useRequiredCurrentUser = () => {
    const user = useCurrentUser();

    if (!user) throw new Error('useCurrentUser returned null but user expected');

    return user;
};
