window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.featured_students = window.locales.am.featured_students || {};window.locales.am.featured_students.featured_students = window.locales.am.featured_students.featured_students || {};window.locales.am.featured_students.featured_students = {...window.locales.am.featured_students.featured_students, ...{
    "preview_network": "አውታረ መረቡን አስቀድመው ይመልከቱ",
    "student_spotlight": "የተማሪ ትኩረት",
    "meet_your_class": "ክፍልዎን ይገናኙ",
    "view_classmates": "ሁሉንም የክፍል ጓደኞች ይመልከቱ",
    "tooltip_message": "ከእነዚህ የክፍል ጓደኞች ጋር ለመገናኘት ፍላጎት ሊኖርዎት ይችላል ብለን እናስባለን።",
    "branded_student_spotlight": "{brandNameShort} የተማሪ ትኩረት"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.featured_students = window.locales.ar.featured_students || {};window.locales.ar.featured_students.featured_students = window.locales.ar.featured_students.featured_students || {};window.locales.ar.featured_students.featured_students = {...window.locales.ar.featured_students.featured_students, ...{
    "preview_network": "معاينة الشبكة",
    "student_spotlight": "تسليط الضوء على الطلاب",
    "meet_your_class": "قابل صفك",
    "view_classmates": "عرض كل زملاء الدراسة",
    "tooltip_message": "نعتقد أنك قد تكون مهتمًا بالتواصل مع زملاء الدراسة هؤلاء.",
    "branded_student_spotlight": "{brandNameShort} طالب في دائرة الضوء"
}
};window.locales.en = window.locales.en || {};window.locales.en.featured_students = window.locales.en.featured_students || {};window.locales.en.featured_students.featured_students = window.locales.en.featured_students.featured_students || {};window.locales.en.featured_students.featured_students = {...window.locales.en.featured_students.featured_students, ...{
    "preview_network": "preview the network",
    "student_spotlight": "student spotlight",
    "branded_student_spotlight": "{brandNameShort} student spotlight",
    "meet_your_class": "meet your class",
    "view_classmates": "view all classmates",
    "tooltip_message": "We think you might be interested in connecting with these classmates."
}
};window.locales.es = window.locales.es || {};window.locales.es.featured_students = window.locales.es.featured_students || {};window.locales.es.featured_students.featured_students = window.locales.es.featured_students.featured_students || {};window.locales.es.featured_students.featured_students = {...window.locales.es.featured_students.featured_students, ...{
    "preview_network": "vista previa de la red",
    "student_spotlight": "estudiante destacado",
    "meet_your_class": "conoce a tu clase",
    "view_classmates": "ver todos los compañeros de clase",
    "tooltip_message": "Creemos que podría estar interesado en conectarse con estos compañeros de clase.",
    "branded_student_spotlight": "Estudiante destacado de {brandNameShort}"
}
};window.locales.it = window.locales.it || {};window.locales.it.featured_students = window.locales.it.featured_students || {};window.locales.it.featured_students.featured_students = window.locales.it.featured_students.featured_students || {};window.locales.it.featured_students.featured_students = {...window.locales.it.featured_students.featured_students, ...{
    "preview_network": "visualizzare in anteprima la rete",
    "student_spotlight": "riflettori studenteschi",
    "meet_your_class": "incontra la tua classe",
    "view_classmates": "visualizza tutti i compagni di classe",
    "tooltip_message": "Pensiamo che potresti essere interessato a entrare in contatto con questi compagni di classe.",
    "branded_student_spotlight": "{brandNameShort} studente in primo piano"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.featured_students = window.locales.zh.featured_students || {};window.locales.zh.featured_students.featured_students = window.locales.zh.featured_students.featured_students || {};window.locales.zh.featured_students.featured_students = {...window.locales.zh.featured_students.featured_students, ...{
    "preview_network": "预览网络",
    "student_spotlight": "学生聚光灯",
    "meet_your_class": "认识你的班级",
    "view_classmates": "查看所有同学",
    "tooltip_message": "我们认为您可能有兴趣与这些同学联系。",
    "branded_student_spotlight": "{brandNameShort}学生聚焦"
}
};