import { type ButtonHTMLAttributes, memo } from 'react';
import { twMerge } from 'tailwind-merge';

const styleClassNames = `
    flex items-center justify-center gap-2
    border border-transparent
    bg-beige-darkest
    hover:brightness-[97%]
    rounded-13
    py-2 px-10
    text-white
    disabled:opacity-60
`;

const FlatButtonComponent: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, children, ...rest }) => (
    <button type="button" className={twMerge(styleClassNames, className)} {...rest}>
        {children}
    </button>
);

export const FlatButton = memo(FlatButtonComponent) as typeof FlatButtonComponent;
