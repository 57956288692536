import { type FrontRoyalRouteService } from 'NavigationHelpers';
import NetworkConnection from 'NetworkConnection';
import hideSplashScreen from 'hideSplashScreen';
import { type FrontRoyalRootScope } from './FrontRoyal.types';

export function cleanUpRouteResolution($injector: ng.auto.IInjectorService) {
    try {
        const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
        const $route = $injector.get<FrontRoyalRouteService>('$route');

        $route.frontRoyalIsResolvingRoute = false;

        hideSplashScreen($injector);

        if (NetworkConnection.online && $rootScope.currentUser) {
            // rather than try to direct this from a downstream directive, assume
            // the "welcome" page is whatever we initially redirect an unregistered user to
            // NOTE: may be preview session, so check that the method exists first.
            if ($rootScope.currentUser.ensureHasSeenWelcome) {
                $rootScope.currentUser.ensureHasSeenWelcome();
            }

            // We used to ensureLoginEvent in the handleValidationSuccess ValidationResponder
            // function, but we noticed that it was getting called multiple times quickly
            // during OAuth registration because of the redirects. So we moved it here
            // so that we call it when the app is hopefully at a stable point.
            // NOTE: may be preview session, so check that the method exists first.
            if ($rootScope.currentUser.ensureLoginEvent) {
                $rootScope.currentUser.ensureLoginEvent();
            }
        }
    } catch (e) {
        console.error('Routing error: ', e);
        throw e;
    }
}
