import { ShowFatalModal } from './errorHandlingComponents/ShowFatalModal';
import { HandleFatalDisconnectedError } from './errorHandlingComponents/HandleFatalDisconnectedError';
import { RedirectToSignIn } from './errorHandlingComponents/RedirectToSignIn';
import { ShowNotPermittedModalAndRedirectToSignIn } from './errorHandlingComponents/ShowNotPermittedModalAndRedirectToSignIn';
import { SomethingWentWrong } from './errorHandlingComponents/SomethingWentWrong';
import { getShowRedirectToSignInButtonComponent } from './errorHandlingComponents/getShowRedirectToSignInButtonComponent';
import { ShowValidationErrors } from './errorHandlingComponents/ShowValidationErrors';
import { type ErrorHandlingOptions } from './FrontRoyalErrorBoundary.types';

/*
    This file holds default configurations that can be passed
    to FrontRoyalErrorBoundary to define how we want to handle
    particular error types. In any particular situation, any of these
    keys can be overridden to provide custom error handling.
*/

// These defaults are used at the top of the UI when an error prevents the main page from loading
// and will render a full page modal. We only expect these to be triggered from angular.
export const errorHandlingModals = {
    disconnected: HandleFatalDisconnectedError,
    notLoggedIn: RedirectToSignIn,
    notPermitted: ShowNotPermittedModalAndRedirectToSignIn,
    other: ShowFatalModal,
} satisfies ErrorHandlingOptions;

// These components are the default components that will be used to handle react errors
// They should be used and/or overridden anywhere in a react tree that
// implements a LoadingBoundary or ErrorBoundary.
export const defaultErrorHandlingComponents = {
    notLoggedIn: getShowRedirectToSignInButtonComponent('notLoggedIn'),
    notPermitted: getShowRedirectToSignInButtonComponent('notPermitted'),
    notAcceptable: ShowValidationErrors,
    other: SomethingWentWrong,
} satisfies ErrorHandlingOptions;
