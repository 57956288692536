import clsx from 'clsx';

export const buttonClasses = clsx([
    'flex',
    'h-7.5',
    'self-center',
    'items-center',
    'mx-auto',
    'mb-[25px]',
    'bg-white',
    'rounded-full',
    'text-xxs',
    'text-beige-for-text',
    'leading-3',
    'uppercase',
    'py-2.5',
    'px-[25px]',
    'shadow-[0_5px_10px_0_rgb(0,0,0,.1)]',
    'border',
    'border-solid',
    'border-beige',
    'font-semibold',
    'transition-shadow',
    'duration-200',
    'ease-in-out',
    'hover:shadow-[0_5px_15px_0_rgb(0,0,0,.2)]',
]);
