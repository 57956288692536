import { type AnyObject } from '@Types';
import { type Brand } from 'AppBranding';

export enum AppEnvType {
    production = 'production',
    staging = 'staging',
    development = 'development',
}

export type Config = {
    appEnvType: () => AppEnvType;
    debugFrameNavPerf: () => boolean;
    isQuantic: () => boolean;
    chinaRegionMode: () => boolean;
    biosigEnabled: () => boolean;
    admitted_registration_url?: string;
    admitted_recording_url?: string;
    admitted_webinar_date?: string;
    admitted_registration_url_valar?: string;
    admitted_recording_url_valar?: string;
    google_api_key?: string;
    gdprAppliesToUser: () => boolean;
    tutorBotOrigin: () => string;
    tutorBotCnOrigin: () => string;
    tutorBotSmartlyOrigin: () => string;
    tutorBotDisabled: () => boolean;
    tutorBotAccessHeaders: () => AnyObject<string>;
    enableQuanticCnImageRewriting: () => boolean;
    content_views_refresh_updated_at: number;
    enable_offline_mode: string;
    preRecordedInterviewUrls: () => Partial<Record<Brand, string>>;
    sendbirdApplicationId: () => string;
    liveKitServerUrl: () => string;
    studentDashboardVersion: () => 1 | 2;
    statsigClientKey: () => string;
};

// See https://dev.to/natelindev/typescript-function-return-type-based-on-parameters-87d for an explanation
// of how we're using an interface here to define different return types based on the value of the withoutThrow param
interface GetSync {
    // getSync might return null if withoutThrow is true
    (withoutThrow: true): Config | null;

    // otherwise getSync always returns a Config object
    (withoutThrow: false): Config;
    (): Config;
}

export type ConfigFactory = {
    // In the future, it maybe become necessary to be more specific about what options supports, and about the return value here.
    // For now, this was all we needed for the places where we were using this type.
    getConfig: () => Promise<Config>;
    getSync: GetSync;
    reloadFromApi: () => Promise<void>;
    watchConfig: (callback: (config: Config) => void) => () => void;
};
